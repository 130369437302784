import ActivityPreviewCard from 'components/ActivityPreviewCard/ActivityPreviewCard';
import classes from './ReviewPayStep.module.scss';
import { Box, Button, Flex, Text, Title, useMantineTheme } from '@mantine/core';
import { IReviewPayFormValues, PreCheckoutBasketTicket, SupplierLink } from 'interfaces';
import SessionCost from 'components/SessionCost/SessionCost';
import { PebbleCheckbox } from 'components/ui';
import classNames from 'classnames';
import { Actions, trackAction } from 'utils/amplitude';
import { useCheckoutDetailsContext } from 'context/CheckoutDetailsContext';
import { useMediaQuery } from '@mantine/hooks';
import { ActivityTypeEnum, CheckoutPaymentMethodEnum } from 'enums';
import { useReviewPayForm } from 'context/CheckoutFormContext';
import PonchoModal from './PonchoModal/PonchoModal';
import { Fragment, useMemo } from 'react';
import BookedClassInfoCard from 'components/SessionCost/BookedClassInfoCard/BookedClassInfoCard';
import BookedSubscriptionInfo from 'components/SessionCost/BookedSubscriptionInfoCard/BookedSubscriptionInfo';
import dayjs from 'dayjs';
import { formatPenceToPounds } from 'utils/formatPrice';
import { Lightning } from '@phosphor-icons/react';

interface IReviewPayStepProps {
  filteredTickets: PreCheckoutBasketTicket[];
  userToken: string;
  freeBookingLoading: boolean;
  handleCheckout: (
    paymentType: CheckoutPaymentMethodEnum,
    reviewPayFormValues: IReviewPayFormValues,
  ) => Promise<void>;
}

const getSupplierLinksLabel = (supplierLinks: SupplierLink[]) => {
  const link = ({ name, url }: SupplierLink) => (
    <a key={url} href={url} target="_blank" rel="noopener noreferrer">
      {name}
    </a>
  );

  const [linkOne, linkTwo, linkThree] = supplierLinks;
  let linksJsx = null;

  if (!linkTwo) {
    linksJsx = <>{link(linkOne)}</>;
  } else if (!linkThree) {
    linksJsx = (
      <>
        {link(linkOne)} and {link(linkTwo)}
      </>
    );
  } else {
    linksJsx = (
      <>
        {link(linkOne)}, {link(linkTwo)} and {link(linkThree)}
      </>
    );
  }

  return <>I've read and accept the provider's: {linksJsx}.</>;
};

const ReviewPayStep: React.FC<IReviewPayStepProps> = ({
  filteredTickets,
  userToken,
  freeBookingLoading,
  handleCheckout,
}: IReviewPayStepProps) => {
  const { basket, setOpenPonchoModal, isLoggedIn } = useCheckoutDetailsContext();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  const supplierLinks = basket.activity.supplier.links;
  const isSubscription = basket.activity.activityType === ActivityTypeEnum.SUBSCRIPTION;

  const form = useReviewPayForm({
    initialValues: {
      // Default guest to opt-in, for guardians use their current status. Checkbox hidden at checkout as this can be changed in GWA.
      isMarketingConsentGiven: isLoggedIn ? Boolean(basket?.isMarketingConsentGiven) : true,
      supplierLinksAccepted:
        supplierLinks?.length > 0 ? basket?.supplierLinksAccepted || false : null,
    },
    validate: {
      supplierLinksAccepted: (value: boolean | null) =>
        value === false ? 'Please check this box to continue.' : null,
    },
  });

  const { tickets, activity, product } = basket;
  const marketingConsent = `We’d love to email you with activity ideas and exclusive offers. If you’d prefer to not receive emails from Pebble, tick this box.`;
  const PRIVACY_POLICY_LINK = `https://www.bookpebble.co.uk/privacy-policy`;
  const T_AND_CS_LINK = `https://www.bookpebble.co.uk/terms`;

  const trialEndDate = useMemo(() => {
    if (!isSubscription) {
      return undefined;
    }
    const timestamp = tickets[0]?.subscriptionTrialEndTimestamp;

    if (!timestamp) {
      return undefined;
    }

    const dayjsObj = dayjs.unix(timestamp);

    return {
      date: dayjsObj.format('DD/MM/YYYY'),
      month: dayjsObj.format('MMMM'),
    };
  }, [isSubscription, tickets]);

  return (
    <Box
      mb={isMobile ? (activity.ponchoPayAccepted ? '145px' : '110px') : '120px'}
      className={classNames(classes.reviewPayStepWrapper, {})}
    >
      <ActivityPreviewCard tickets={tickets} activity={activity} product={product} />
      {!isSubscription ? (
        <>
          {basket.classes?.map((classItem) => {
            const matchingTickets = basket.tickets.filter(
              (ticket) => ticket.classId === classItem.id,
            );

            const costOfAllTicketsPerClass = matchingTickets.reduce((acc, ticket) => {
              const sessionsAvailable = ticket.product?.numberOfSessionsAvailable;
              if (!sessionsAvailable) return acc;

              return acc + ticket.amount / 100;
            }, 0);

            const allBookingAddOnsPerClass = matchingTickets.flatMap((ticket) => {
              return ticket.addons.flatMap((addOn) => [...(addOn.perBooking ?? [])]);
            });

            const allSessionAddOnsPerClass = matchingTickets.flatMap((ticket) => {
              return ticket.addons.flatMap((addOn) => [...(addOn.perSession ?? [])]);
            });

            const costOfAllBookingAddonsPerClass = allBookingAddOnsPerClass.reduce((acc, addon) => {
              if (addon.quantity === 0) return acc;
              return acc + Number(addon.addonOption.price / 100) * addon.quantity;
            }, 0);

            const costOfAllSessionAddonsPerClass = allSessionAddOnsPerClass.reduce((acc, addon) => {
              const quantity = addon.selectedSessions.length;
              if (quantity === 0) return acc;
              return acc + Number(addon.addonOption.price / 100) * quantity;
            }, 0);

            const costOfAllAddonsPerClass =
              costOfAllBookingAddonsPerClass + costOfAllSessionAddonsPerClass;

            return (
              <Fragment key={classItem.id}>
                <BookedClassInfoCard
                  matchingTickets={matchingTickets}
                  className={classItem.name}
                  costOfAllTicketsPerClass={costOfAllTicketsPerClass}
                  costOfAllAddonsPerClass={costOfAllAddonsPerClass}
                />
              </Fragment>
            );
          })}
        </>
      ) : (
        <BookedSubscriptionInfo
          filteredTickets={filteredTickets}
          tickets={tickets}
          trialEndDate={trialEndDate?.date}
          subscriptionStart={activity.subscriptionStart}
        />
      )}
      <SessionCost
        userToken={userToken}
        tickets={tickets}
        basket={basket}
        trialEndDate={trialEndDate}
      />
      {supplierLinks?.length > 0 && (
        <div className={classes.consentBoxWrapper}>
          <PebbleCheckbox
            className={classNames({
              [classes.supplierLinkConsent]: form.getInputProps('supplierLinksAccepted').error,
            })}
            required
            onClick={(e) => {
              if (e.currentTarget.checked) {
                trackAction(Actions.SUPPLIER_LINKS_ACCEPTED);
              }
            }}
            {...form.getInputProps('supplierLinksAccepted', { type: 'checkbox' })}
            label={getSupplierLinksLabel(supplierLinks)}
            checkboxLabelOriginal
            data-testid="supplierLinksConsent"
          />
        </div>
      )}
      {!userToken && (
        <>
          <PebbleCheckbox
            label={marketingConsent}
            checkboxLabelOriginal
            {...form.getInputProps('isMarketingConsentGiven', { type: 'checkbox' })}
            onChange={(e) => {
              if (e.target.checked) {
                trackAction(Actions.GUEST_CHECKOUT_MARKETING);
              }
              form.setFieldValue('isMarketingConsentGiven', !form.values.isMarketingConsentGiven);
            }}
            checked={!form.values.isMarketingConsentGiven}
            data-testid="marketingConsent"
          />
          <Text className={classes.proceedToPaymentNotice}>
            By clicking "Proceed to payment" you agree to Pebble's{' '}
            <a href={T_AND_CS_LINK} target="_blank" rel="noopener noreferrer">
              T&C's
            </a>{' '}
            and{' '}
            <a href={PRIVACY_POLICY_LINK} target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            .
          </Text>
        </>
      )}

      <Box className={classes.nextButtonWrapper}>
        {basket.originalAmount > 0 && basket.finalAmount > 0 && (
          <Flex justify="center">
            <Flex direction="column">
              <Flex justify="space-between" mx="xl" align="center" mt="xs">
                <Title size="20px" fw={700} c={theme.colors.blue[8]}>
                  Total cost
                </Title>
                <Text
                  className={classNames(classes.price, {
                    [classes.freeTotal]: basket.finalAmount === 0,
                  })}
                  fw={700}
                  data-testid="total-cost"
                >
                  {formatPenceToPounds(basket.finalAmount)}
                </Text>
              </Flex>

              <Button
                leftSection={<Lightning weight="fill" />}
                fullWidth
                className={classes.nextButton}
                onClick={() => {
                  if (activity?.ponchoPayAccepted && !form.validate().hasErrors) {
                    setOpenPonchoModal(true);
                  } else {
                    const { hasErrors } = form.validate();
                    if (!hasErrors) {
                      handleCheckout(CheckoutPaymentMethodEnum.STRIPE, form.values);
                    }
                  }
                }}
                disabled={!!basket.promotion?.code && basket.finalAmount === 0}
              >
                Proceed to Payment
              </Button>
            </Flex>
          </Flex>
        )}
        {basket.finalAmount === 0 && !freeBookingLoading && (
          <Flex justify="center" mt="lg">
            <Button
              fullWidth
              className={classes.nextButton}
              onClick={() => {
                const { hasErrors } = form.validate();
                if (!hasErrors) {
                  handleCheckout(CheckoutPaymentMethodEnum.FREE_BOOKING, form.values);
                }
              }}
            >
              Book
            </Button>
          </Flex>
        )}
      </Box>
      <PonchoModal
        handleCheckout={(paymentType: CheckoutPaymentMethodEnum) =>
          handleCheckout(paymentType, form.values)
        }
        supplierName={activity?.supplier?.name}
      />
    </Box>
  );
};

export default ReviewPayStep;
