import {
  Badge,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Grid,
  Group,
  rem,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import { IconActivity } from '@icons';
import DesktopSuccessfulBooking from '../../../../public/desktop_confirmation.png';
import MobileSuccessfulBooking from '../../../../public/mobile_confirmation.png';
import { Actions, trackAction } from 'utils/amplitude';
import Link from 'next/link';
import Image from 'next/legacy/image';
import {
  AnonymisedActivityBookingSchemaType,
  AnonymisedBookingTicketSessionsType,
  BookingConfirmationAttendee,
} from 'types';
import { ActivityBookingStatusEnum, ActivityBookingTypeEnum } from 'enums';
import ReviewSection from 'components/ReviewSection/ReviewSection';
import { useMediaQuery } from '@mantine/hooks';
import {
  calculatePerSessionAddons,
  calculateTickets,
  getFullTicketsPricePerClass,
  getUniqueSessions,
} from './Success.utils';
import { Fragment, useEffect, useMemo, useState } from 'react';
import classes from '../index.module.scss';
import { formatPenceToPounds } from 'utils/formatPrice';
import classNames from 'classnames';
import { CalendarBlank, Clock, MapPin, Tag, Ticket, VideoCamera } from '@phosphor-icons/react';

dayjs.extend(advancedFormat);
dayjs.extend(utc);

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: Array<any>;
  }
}

interface IStripeSuccess {
  booking: AnonymisedActivityBookingSchemaType;
  userToken: string | null;
  reviewDisabled: boolean;
}

const SessionList = ({
  sessions,
  hideEndTimes,
}: {
  sessions: AnonymisedBookingTicketSessionsType[];
  hideEndTimes: boolean;
}) => {
  const theme = useMantineTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => setIsExpanded(!isExpanded);

  const displayedSessions = isExpanded ? sessions : sessions.slice(0, 3);

  return (
    <Stack gap={8}>
      <Stack className={classes.listOfSessions} gap={2}>
        {displayedSessions.map((session, index) => (
          <Flex gap={8} key={index}>
            <Text>{dayjs(session.sessionStart).utc().format('DD MMM')}</Text>
            <Text>-</Text>
            {!hideEndTimes ? (
              <Text className={classes.sessionsTimes}>
                {dayjs(session.sessionStart).utc().format('HH:mm')} -{' '}
                {dayjs(session.sessionEnd).utc().format('HH:mm')}
              </Text>
            ) : (
              <Text className={classes.sessionsTimes}>
                {dayjs(session.sessionStart).utc().format('HH:mm')}
              </Text>
            )}
          </Flex>
        ))}
        {sessions.length > 3 && (
          <Text
            c={theme.colors.blue[6]}
            mt={8}
            onClick={toggleExpand}
            className={classes.viewMoreLess}
          >
            {isExpanded ? 'View less' : 'View more'}
          </Text>
        )}
      </Stack>
    </Stack>
  );
};

export const Success: React.FC<IStripeSuccess> = ({ userToken, reviewDisabled, booking }) => {
  const theme = useMantineTheme();

  const isMobile = useMediaQuery(`(max-width: 465px)`, true);

  const { isOnline, location, supplier, subscriptionStart, hideEndTimes } = booking.activity;

  const uniqueSessions = useMemo<AnonymisedBookingTicketSessionsType[]>(() => {
    if (booking.bookedTickets[0]?.sessions) {
      return booking.bookedTickets[0]?.sessions.filter(
        (session, index, self) =>
          index ===
          self.findIndex(
            (s) => s.sessionStart === session.sessionStart && s.sessionEnd === session.sessionEnd,
          ),
      );
    } else {
      return [];
    }
  }, [booking.bookedTickets]);

  const isSubscription = booking.bookingType === ActivityBookingTypeEnum.SUBSCRIPTION;

  const isSubsTrialSelected = booking.bookedTickets[0]?.subscriptionTrialSelected;

  const trialCost = booking.bookedTickets[0]?.subscriptionTrialPrice;

  const trialLength = booking.bookedTickets[0]?.subscriptionTrialSessionCount;

  const isFutureSub = Boolean(subscriptionStart);

  const paymentCompleted =
    booking.status === ActivityBookingStatusEnum.CONFIRMED &&
    (!isSubscription || !isSubsTrialSelected);

  const paymentPending =
    booking.status === ActivityBookingStatusEnum.CONFIRMED && isSubsTrialSelected;

  const firstStartDate = uniqueSessions?.[0]?.sessionStart;
  const firstEndDate = uniqueSessions?.[0]?.sessionEnd;

  const formattedNextSessionDate = useMemo(() => {
    if (firstStartDate) {
      return dayjs(firstStartDate).utc().format('Do MMMM YYYY');
    }
    if (booking.bookedTickets[0]?.firstSessionToBe) {
      return dayjs(booking.bookedTickets[0]?.firstSessionToBe).utc().format('Do MMMM YYYY');
    }
    return '';
  }, [firstStartDate, booking.bookedTickets]);

  const localStorageFullName = useMemo(() => {
    if (typeof window === 'undefined') {
      return '';
    }
    return window && window.sessionStorage.getItem('fullname');
  }, []);
  const localStorageEmail = useMemo(() => {
    if (typeof window === 'undefined') {
      return '';
    }
    return window && window.sessionStorage.getItem('email');
  }, []);

  const startTime = dayjs(firstStartDate).utc().format('HH:mm');
  const endTime = dayjs(firstEndDate).utc().format('HH:mm');

  const activityTime = hideEndTimes ? startTime : `${startTime} - ${endTime}`;

  const opts = {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  } as Intl.NumberFormatOptions;

  useEffect(() => {
    if (window?.dataLayer) {
      window.dataLayer.push({
        event: 'order_complete',
        order_id: booking.id,
        order_value: booking.finalAmount,
        order_currency: 'GBP',
        ...(booking.promotion?.code && {
          order_promotion_code: booking.promotion.code,
        }),
      });
    }
  }, [booking.id, booking.finalAmount, booking.promotion?.code]);

  useEffect(() => {
    const reqBody = {
      amount: booking?.finalAmount,
      id: booking?.id,
      promoCode: booking?.promotion?.code,
    };
    fetch('/api/conversion_tracking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(reqBody),
    });
  }, [booking.finalAmount, booking.id, booking.promotion]);

  return (
    <>
      <div>
        <Image
          src={isMobile ? MobileSuccessfulBooking : DesktopSuccessfulBooking}
          alt="parent and child on shaped background"
        />
        <Flex className={classes.wrapper} direction={'column'}>
          <Title order={3} className={classes.header} style={{ textAlign: 'center' }}>
            Booking confirmed!
          </Title>
          <Text className={classes.confirmationText} fw={600} style={{ textAlign: 'center' }}>
            We will send you your booking confirmation shortly
          </Text>
          {!reviewDisabled && localStorageFullName && localStorageEmail && (
            <ReviewSection
              activityId={booking.activity.id}
              fullName={JSON.parse(localStorageFullName)}
              email={JSON.parse(localStorageEmail)}
            />
          )}
          <Box className={classes.scheduleCard} mt={28}>
            {!isSubscription && (
              <>
                <Text fw={700} className={classes.activityName}>
                  {booking.activity.name}
                </Text>
                <Box mt={14}>
                  {isOnline ? (
                    <Text c={theme.colors.blue[5]} fw={700}>
                      ONLINE
                    </Text>
                  ) : (
                    location && (
                      <Flex align={'center'} gap={8}>
                        <MapPin size={24} color={theme.colors.gray[6]} />
                        <Text size="md" fw={600} c={theme.colors.gray[6]}>
                          {location.addressLine1} {location.addressLine2}, {location.city},{' '}
                          {location.postCode}
                        </Text>
                      </Flex>
                    )
                  )}
                </Box>
              </>
            )}
            {!isSubscription &&
              booking.bookedClasses.map((classItem) => {
                const isTotalFree = getFullTicketsPricePerClass(classItem) === 0;
                return (
                  <Card className={classes.informationCard} w={'100%'} mt={28} key={classItem.id}>
                    <Flex justify={'space-between'} align={'center'}>
                      <Text c={theme.colors.blue[8]} fw={700} className={classes.scheduleName}>
                        {classItem.name}
                      </Text>
                      <Text
                        c={isTotalFree ? theme.colors.pink[6] : theme.colors.blue[6]}
                        fw={700}
                        className={classes.totalCost}
                        mr={isMobile ? 8 : 16}
                      >
                        {isTotalFree
                          ? 'Free'
                          : Number(getFullTicketsPricePerClass(classItem)).toLocaleString(
                              'en-GB',
                              opts,
                            )}
                      </Text>
                    </Flex>
                    <Grid mt={18}>
                      {classItem.bookedTickets.map((bookedTicket, index) => {
                        return (
                          <Fragment key={bookedTicket.id}>
                            <Grid.Col span={isMobile ? 12 : 4}>
                              {index !== 0 && isMobile && (
                                <Divider
                                  orientation="horizontal"
                                  variant="dashed"
                                  color={theme.colors.gray[4]}
                                  mb="sm"
                                />
                              )}
                              <Stack gap={8}>
                                <Text c={theme.colors.gray[6]} size={'xs'} fw={600}>
                                  Selected session(s):
                                </Text>
                                <Stack className={classes.listOfSessions} gap={2}>
                                  <SessionList
                                    sessions={getUniqueSessions(bookedTicket.sessions)}
                                    hideEndTimes={hideEndTimes}
                                  />
                                </Stack>
                              </Stack>
                            </Grid.Col>
                            <Grid.Col span={isMobile ? 12 : 8}>
                              <Card className={classes.childInfoCard}>
                                <Flex justify={'space-between'}>
                                  <Flex>
                                    <Ticket
                                      size={21}
                                      weight="duotone"
                                      fill={theme.colors.blue[8]}
                                    />
                                    <Text
                                      className={classes.ticketDetails}
                                      c={theme.colors.blue[8]}
                                      fw={700}
                                    >
                                      {bookedTicket.ticketNameAtBooking}
                                    </Text>
                                  </Flex>
                                  <Text
                                    c={
                                      bookedTicket.ticketPriceAtBooking === 0
                                        ? theme.colors.pink[6]
                                        : theme.colors.blue[6]
                                    }
                                    fw={700}
                                  >
                                    {bookedTicket.ticketPriceAtBooking === 0
                                      ? 'Free'
                                      : Number(bookedTicket.originalAmount / 100).toLocaleString(
                                          'en-GB',
                                          opts,
                                        )}
                                  </Text>
                                </Flex>

                                {bookedTicket.attendees.map(
                                  (attendee: BookingConfirmationAttendee) => {
                                    return (
                                      <Fragment key={attendee.attendeeId}>
                                        <Text mt={12} fw={400} size={'md'}>
                                          {attendee.attendeeName}
                                        </Text>
                                        {calculatePerSessionAddons(attendee.addons.perSession)
                                          .length > 0 &&
                                          calculatePerSessionAddons(attendee.addons.perSession).map(
                                            (perSessionAddOn) => {
                                              const isFree =
                                                perSessionAddOn.addonsPriceInPence === 0;
                                              return (
                                                <Flex
                                                  justify={'space-between'}
                                                  mt={12}
                                                  key={perSessionAddOn.addonsString}
                                                >
                                                  <Text
                                                    c={theme.colors.blue[8]}
                                                    fw={700}
                                                    size={'md'}
                                                  >
                                                    {perSessionAddOn.addonsString}
                                                  </Text>
                                                  <Text
                                                    c={
                                                      isFree
                                                        ? theme.colors.pink[6]
                                                        : theme.colors.blue[6]
                                                    }
                                                    fw={700}
                                                    size={'md'}
                                                  >
                                                    {isFree
                                                      ? 'Free'
                                                      : Number(
                                                          perSessionAddOn.addonsPriceInPence / 100,
                                                        ).toLocaleString('en-GB', opts)}
                                                  </Text>
                                                </Flex>
                                              );
                                            },
                                          )}
                                        {attendee.addons.perBooking.length > 0 &&
                                          attendee.addons.perBooking.map((perBookingAddOn) => {
                                            const isFree = perBookingAddOn.price === 0;
                                            return (
                                              <Flex justify={'space-between'} mt={12}>
                                                <Text c={theme.colors.blue[8]} fw={700} size={'md'}>
                                                  {`${perBookingAddOn.quantity || 1} x ${
                                                    perBookingAddOn.name
                                                  }`}
                                                </Text>
                                                <Text
                                                  c={
                                                    isFree
                                                      ? theme.colors.pink[6]
                                                      : theme.colors.blue[6]
                                                  }
                                                  fw={700}
                                                  size={'md'}
                                                >
                                                  {isFree
                                                    ? 'Free'
                                                    : Number(
                                                        perBookingAddOn.price / 100,
                                                      ).toLocaleString('en-GB', opts)}
                                                </Text>
                                              </Flex>
                                            );
                                          })}
                                      </Fragment>
                                    );
                                  },
                                )}
                              </Card>
                            </Grid.Col>
                          </Fragment>
                        );
                      })}
                    </Grid>
                  </Card>
                );
              })}
            {isSubscription && (
              <Card className={classes.card}>
                <Grid columns={12} align="center">
                  <Grid.Col span={5} py="sm">
                    <Flex align={'center'}>
                      <IconActivity size={24} color={theme.colors.gray[6]} />
                      <Text fw={600} c={theme.colors.gray[6]}>
                        Activity
                      </Text>
                    </Flex>
                  </Grid.Col>
                  <Grid.Col span={7} py="sm" className={classes.bookingDetailsInfo}>
                    {booking.activity.name}
                  </Grid.Col>
                  <Grid.Col span={12} className={classes.tableLine}></Grid.Col>
                  <>
                    <Grid.Col span={5} py="sm">
                      <Flex align="center">
                        <CalendarBlank size={24} color={theme.colors.gray[6]} />
                        <Text fw={600} c={theme.colors.gray[6]}>
                          Next date
                        </Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col span={7} py="sm" className={classes.bookingDetailsInfo}>
                      {formattedNextSessionDate}
                      <br />
                      {isSubsTrialSelected && !trialCost && (
                        <Text className={classes.freeSession} c={theme.colors.blue[5]}>
                          {trialLength === 1
                            ? 'First session free'
                            : `First ${trialLength} sessions free`}
                        </Text>
                      )}
                    </Grid.Col>
                    <Grid.Col span={12} className={classes.tableLine}></Grid.Col>
                    <Grid.Col span={5} py="sm">
                      <Flex align="center">
                        <Clock size={24} strokeWidth={1.75} color={theme.colors.gray[6]} />
                        <Text fw={600} c={theme.colors.gray[6]}>
                          Session
                        </Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col span={7} py="sm" className={classes.bookingDetailsInfo}>
                      {activityTime && (
                        <Text c={theme.colors.blue[8]} fw={700}>
                          {booking.bookedTickets[0].subscriptionDayOfWeek}s {activityTime}
                        </Text>
                      )}
                    </Grid.Col>
                  </>
                  <Grid.Col span={12} className={classes.tableLine}></Grid.Col>
                  <Grid.Col span={5} py="sm">
                    <Flex align="center">
                      <Ticket size={24} color={theme.colors.gray[6]} />
                      <Text c={theme.colors.gray[6]} fw={600}>
                        Tickets
                      </Text>
                    </Flex>
                  </Grid.Col>
                  <Grid.Col span={7} py="sm" className={classes.bookingDetailsInfo}>
                    <Text className={classes.ticketDetails} c={theme.colors.blue[8]} fw={700}>
                      {calculateTickets(booking.bookedTickets)}
                    </Text>
                  </Grid.Col>

                  <Grid.Col span={12} className={classes.tableLine}></Grid.Col>
                  {isOnline ? (
                    <>
                      <Grid.Col span={5} py="sm">
                        <Flex align="center">
                          <VideoCamera size={24} color={theme.colors.gray[6]} />
                          <Text c={theme.colors.gray[6]} fw={600}>
                            Location
                          </Text>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col span={7} py="sm" className={classes.bookingDetailsInfo}>
                        <Text c={theme.colors.blue[5]} fw={700}>
                          ONLINE
                        </Text>
                      </Grid.Col>
                    </>
                  ) : (
                    <>
                      <Grid.Col span={5} py="sm">
                        <Flex align="center">
                          <MapPin size={24} color={theme.colors.gray[6]} />
                          <Text c={theme.colors.gray[6]} fw={600}>
                            Location
                          </Text>
                        </Flex>
                      </Grid.Col>
                      {location && (
                        <Grid.Col span={7} py="sm" fw={700} className={classes.bookingDetailsInfo}>
                          {location.addressLine1} {location.addressLine2}, {location.city},{' '}
                          {location.postCode}{' '}
                        </Grid.Col>
                      )}
                    </>
                  )}
                </Grid>
              </Card>
            )}
          </Box>

          <Text size="sm" fw={400} my={40}>
            {supplier.name} may be in touch via email if required.
          </Text>
          {isSubscription && isSubsTrialSelected && (
            <>
              <Text component="h4">Payment</Text>
              <div className={classes.priceSection}>
                {trialCost ? (
                  <>
                    <Group>
                      <Text component="h4">Total cost</Text>
                      <Badge className={classes.badge}>Paid</Badge>
                    </Group>
                    <span className={classes.price}>{formatPenceToPounds(trialCost)}</span>
                  </>
                ) : (
                  <>
                    <Text component="h4">Total cost</Text>
                    <span className={classNames(classes.price, classes.free)}>Free</span>
                  </>
                )}
              </div>
            </>
          )}
          {isSubscription && !isSubsTrialSelected && !isFutureSub && (
            <>
              <div className={classes.priceSection}>
                <Text component="h4">Total monthly cost</Text>
                <span className={classes.price}>
                  {Number(booking.finalAmount).toLocaleString('en-GB', opts)}
                </span>
              </div>
            </>
          )}
          {isSubscription && !isSubsTrialSelected && isFutureSub && (
            <section>
              <div className={classes.priceSection}>
                <Text component="h4">Total monthly cost</Text>
                <span className={classes.price}>
                  {formatPenceToPounds(booking.bookedTickets[0].ticketPriceAtBooking)}
                </span>
              </div>
            </section>
          )}

          {booking?.promotion?.code && (
            <Box mb={34}>
              <Text c={theme.colors.gray[6]} size={'sm'}>
                Discount
              </Text>
              <Flex justify={'space-between'} mt={8}>
                <Flex gap={10}>
                  <Tag
                    size={18}
                    weight="duotone"
                    color={theme.colors.gray[7]}
                    transform={'rotate(90)'}
                  />
                  <Text fw={400} size={'md'} c={theme.colors.gray[6]}>
                    {booking.promotion.code}
                  </Text>
                </Flex>
                <Text c={theme.colors.blue[6]} fw={700} size={'md'}>
                  -
                  {(Number(booking.originalAmount) - Number(booking.finalAmount)).toLocaleString(
                    'en-GB',
                    opts,
                  )}
                </Text>
              </Flex>
            </Box>
          )}

          {!isSubscription && booking.finalAmount !== '0.00' && (
            <Flex justify={'space-between'}>
              <Text component="h4" mb={10} size={rem(20)} fw={700} c={theme.colors.blue[8]}>
                Total cost
              </Text>
              <Flex className={classes.priceSection} direction={'column'} align={'flex-end'}>
                <Group>
                  {paymentPending && <Badge className={classes.badge}>Pending</Badge>}
                  {paymentCompleted && <Badge className={classes.badge}>Charged</Badge>}
                  <Text c={theme.colors.blue[6]} fw={700} size={rem(20)}>
                    {Number(booking.finalAmount).toLocaleString('en-GB', opts)}
                  </Text>
                </Group>
                {booking?.promotion?.code &&
                  Number(booking.finalAmount) < Number(booking.originalAmount) && (
                    <Text fw={400} size={'sm'} c={theme.colors.gray[6]} mt={12}>
                      You saved{' '}
                      {(
                        Number(booking.originalAmount) - Number(booking.finalAmount)
                      ).toLocaleString('en-GB', opts)}
                    </Text>
                  )}
              </Flex>
            </Flex>
          )}

          {/* 100% discounted basket of Free activity */}
          {!isSubscription && booking.finalAmount === '0.00' && (
            <section>
              <div className={classes.priceSection}>
                <Text component="h4">Total cost</Text>
                <span className={classNames(classes.price, classes.free)}>Free</span>
              </div>
            </section>
          )}

          <section>
            <Text component="h5">Cancellation policy</Text>
            {!isSubscription && (
              <p>
                If you cancel your booking you might not receive a refund. Please contact the
                supplier or revisit the terms & conditions for further information.
              </p>
            )}
            {isSubscription && !isSubsTrialSelected && (
              <p>
                If you do not wish to continue, then you will need to contact the provider to
                request cancellation. Cancel via provider anytime (subject to provider T&C's).
              </p>
            )}
            {isSubscription && isSubsTrialSelected && (
              <>
                <Text mb="sm">
                  The subscription begins after the trial period unless cancelled at your request.
                </Text>
                <Text mb="sm">
                  After your trial ends we'll calculate the amount you need to pay for the remaining
                  days in the month. We'll charge this immediately after the trial ends.
                </Text>
                <Text mb="sm">
                  If you do not wish to continue, then you will need to contact the provider to
                  request cancellation after the trial period has ended. Cancel via provider anytime
                  (subject to provider T&C's).
                </Text>
              </>
            )}
          </section>
          <section className={classes.footer}>
            <Link href={`/supplier/${supplier.slug}`} passHref legacyBehavior>
              <Button
                component="a"
                radius="lg"
                size="md"
                fullWidth
                className={classes.button}
                onClick={() => {
                  trackAction(Actions.MARKETPLACE_EXPLORE_MORE_ACTIVITIES);
                }}
              >
                Explore More From This Provider
              </Button>
            </Link>
            {userToken ? (
              <Button
                mt="sm"
                component="a"
                href={process.env.NEXT_PUBLIC_PEBBLE_APP_ENDPOINT}
                radius="lg"
                size="md"
                fullWidth
                variant="outline"
                onClick={() => {
                  trackAction(Actions.MARKETPLACE_GO_TO_HOMEPAGE);
                }}
                className={classes.outlineButton}
              >
                Go to Homepage
              </Button>
            ) : (
              <p>
                Want to checkout faster next time? <br />{' '}
                <a
                  href={`${process.env.NEXT_PUBLIC_PEBBLE_APP_ENDPOINT}register`}
                  className={classes.secondaryLink}
                  onClick={() => {
                    trackAction(Actions.MARKETPLACE_CONFIRMATION_ACCOUNT_CREATE);
                  }}
                >
                  Create an account
                </a>
              </p>
            )}
          </section>
        </Flex>
        <div className={classes.thankyouSection}>
          <Text className={classes.thankyouText} c={theme.colors.blue[8]}>
            THANK YOU!
          </Text>
        </div>
      </div>
    </>
  );
};
