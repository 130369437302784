import React, { Fragment } from 'react';
import classes from './BookedClassInfoCard.module.scss';
import { Box, Card, Flex, Grid, Stack, Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Ticket } from '@phosphor-icons/react';
import { PreCheckoutBasketTicket } from 'interfaces';
import SelectedSessionsList from 'components/CheckoutDetails/CheckoutAttendees/SelectedSessionsList/SelectedSessionsList';

interface IBookedClassInfoCardProps {
  matchingTickets: PreCheckoutBasketTicket[];
  costOfAllTicketsPerClass: number;
  costOfAllAddonsPerClass: number;
  className: string;
}

const BookedClassInfoCard: React.FC<IBookedClassInfoCardProps> = ({
  matchingTickets,
  className,
  costOfAllTicketsPerClass,
  costOfAllAddonsPerClass,
}) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  const opts = {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  } as Intl.NumberFormatOptions;

  const totalCostPerClass = costOfAllAddonsPerClass + costOfAllTicketsPerClass;

  return (
    <Box className={classes.scheduleCard} mt={28}>
      <Card className={classes.informationCard} w={'100%'} mt={28}>
        <Flex justify={'space-between'} align={'center'}>
          <Text c={theme.colors.blue[8]} fw={700} className={classes.scheduleName}>
            {className}
          </Text>
          <Text
            c={theme.colors.blue[6]}
            fw={700}
            className={classes.totalCost}
            mr={isMobile ? 8 : 16}
          >
            {totalCostPerClass === 0 ? 'Free' : totalCostPerClass.toLocaleString('en-GB', opts)}
          </Text>
        </Flex>
        <Grid mt={18}>
          {matchingTickets.map((ticket) => {
            if (!ticket.product?.numberOfSessionsAvailable) return;

            const ticketAmount = ticket.amount / 100;

            return (
              <Fragment key={ticket.id}>
                <Grid.Col span={isMobile ? 12 : 4} key={ticket.id}>
                  <Stack gap={8}>
                    <Text c={theme.colors.gray[6]} size={'xs'} fw={600}>
                      Selected sessions:
                    </Text>
                    <Stack className={classes.listOfSessions} gap={2}>
                      <SelectedSessionsList
                        product={ticket?.product}
                        basketTickets={matchingTickets}
                      />
                    </Stack>
                  </Stack>
                </Grid.Col>
                <Grid.Col span={isMobile ? 12 : 8}>
                  <Card className={classes.childInfoCard}>
                    <Flex justify={'space-between'}>
                      <Flex>
                        <Ticket size={21} weight="duotone" fill={theme.colors.blue[8]} />
                        <Text className={classes.ticketDetails} c={theme.colors.blue[8]} fw={700}>
                          {ticket.ticketName}
                        </Text>
                      </Flex>
                      <Text
                        c={ticket.ticketPrice === 0 ? theme.colors.pink[6] : theme.colors.blue[6]}
                        fw={700}
                      >
                        {ticket.ticketPrice === 0
                          ? 'Free'
                          : ticketAmount.toLocaleString('en-GB', opts)}
                      </Text>
                    </Flex>
                    {ticket.addons.map(({ attendee, perBooking, perSession }) => {
                      return (
                        <Fragment key={attendee.id}>
                          <Text size="16px" fw={400} c={theme.colors.blue[8]} mt="sm">
                            {attendee.fullName}
                          </Text>
                          {perSession.map((session) => {
                            if (session.selectedSessions.length === 0) return;

                            const addonTotal =
                              session.selectedSessions.length * (session.addonOption.price / 100);

                            return (
                              <Flex justify={'space-between'} mt="xs">
                                <Text c={theme.colors.blue[8]} fw={700} size={'md'}>
                                  {session.selectedSessions.length} x {session.addonOption.name}
                                </Text>
                                <Text c={theme.colors.blue[6]} fw={700} size={'md'}>
                                  {addonTotal.toLocaleString('en-GB', opts)}
                                </Text>
                              </Flex>
                            );
                          })}
                          {perBooking.map((booking) => {
                            if (booking.quantity === 0) return;
                            const addonTotal = booking.quantity * (booking.addonOption.price / 100);

                            return (
                              <Flex justify={'space-between'} mt={12}>
                                <Text c={theme.colors.blue[8]} fw={700} size={'md'}>
                                  {booking.quantity} x {booking.addonOption.name}
                                </Text>
                                <Text c={theme.colors.blue[6]} fw={700} size={'md'}>
                                  {addonTotal.toLocaleString('en-GB', opts)}
                                </Text>
                              </Flex>
                            );
                          })}
                        </Fragment>
                      );
                    })}
                  </Card>
                </Grid.Col>
              </Fragment>
            );
          })}
        </Grid>
      </Card>
    </Box>
  );
};

export default BookedClassInfoCard;
