import { Spoiler, Text } from '@mantine/core';
import { DateAvailabilityType, Product } from 'types';
import { PreCheckoutBasketTicket, Schedule } from 'interfaces';
import { useMemo } from 'react';
import { trackAction, Actions } from 'utils/amplitude';
import classes from './SelectedSessionsList.module.scss';
import classNames from 'classnames';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useCheckoutDetailsContext } from 'context/CheckoutDetailsContext';
import {
  getBookedDatesList,
  getBookedSubscriptionInfo,
} from 'components/CheckoutDetails/CheckoutDetailsUtils';
dayjs.extend(advancedFormat);

interface ISelectedSessionsList {
  basketTickets: PreCheckoutBasketTicket[];
  product?: Product;
}

const SelectedSessionsList: React.FC<ISelectedSessionsList> = ({ basketTickets, product }) => {
  const { isSubscription, basket } = useCheckoutDetailsContext();
  const { activity } = basket;

  const dates = useMemo(
    () => getBookedDatesList(product, basketTickets) || [],
    [product, basketTickets],
  );
  const subscriptionInfo = isSubscription ? getBookedSubscriptionInfo(product) : null;
  const hideEndTimes = activity?.hideEndTimes;

  const splitDates = useMemo(() => {
    const cutOff = 3;
    return [dates.slice(0, cutOff), dates.slice(cutOff, dates.length)];
  }, [dates]);

  const getDateListItem = (date: DateAvailabilityType | Schedule) => {
    const dateObj = dayjs(date.date);
    const dateString = dateObj.format('DD MMM');
    const isSoldOut = date.spotsLeft === 0;

    return (
      <div
        className={classNames(classes.sessionDateInfo, {
          [classes.soldOut]: isSoldOut,
        })}
        key={date.date}
      >
        <Text>
          {dateString} {hideEndTimes ? date.startTime : `${date.startTime} - ${date.endTime}`}
        </Text>
      </div>
    );
  };

  if (subscriptionInfo) {
    const formattedDate = subscriptionInfo?.date
      ? dayjs(subscriptionInfo.date).format('DD MMM')
      : '';
    return (
      <div className={classes.sessionDateInfo}>
        <Text>NEXT:</Text>
        <Text>
          {formattedDate} {`${subscriptionInfo.startTime} - ${subscriptionInfo.endTime}`}
        </Text>
      </div>
    );
  }

  return (
    <Spoiler
      maxHeight={splitDates[1].length > 0 ? 55 : 9999}
      showLabel={`View more`}
      hideLabel="See less"
      classNames={{
        control: classes.spoilerControl,
      }}
      onClick={() => {
        trackAction(Actions.VIEW_DATES_DETAILS);
      }}
    >
      {dates.map(getDateListItem)}
    </Spoiler>
  );
};

export default SelectedSessionsList;
