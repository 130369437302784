import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  ActionIcon,
  Text,
  useMantineTheme,
  Flex,
  useCombobox,
  Combobox,
  InputBase,
  Input,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { SubscriptionTrialTypeEnum } from 'enums';
import { Actions, trackAction } from 'utils/amplitude';
import { PebbleCheckbox, PebbleTextInput } from 'components/ui';
import { PebblePopover } from 'components/ui/PebblePopover/PebblePopover';
import classes from '../CheckoutTicketAccordionCard/CheckoutTicketAccordionCard.module.scss';
import classNames from 'classnames';
import { CaretDown, Info } from '@phosphor-icons/react';
import { useCheckoutAttendeesFormContext } from 'context/CheckoutFormContext';
import { useForm } from '@mantine/form';
import { validateFullName } from '@pebble/common';
import { PreCheckoutBasketAttendee } from 'interfaces';
import { Child } from 'types';
import { useCheckoutDetailsContext } from 'context/CheckoutDetailsContext';
dayjs.extend(customParseFormat);
import { v4 as uuidv4 } from 'uuid';

interface IAttendeeInfoFormProps {
  subscriptionTrialInfo: {
    trialType?: SubscriptionTrialTypeEnum;
    trialLength?: number;
    trialCost?: number;
  };
  ticketIndex: number;
  attendeeIndex: number;
  checkoutValue?: string;
}

const AttendeeInfoForm: React.FC<IAttendeeInfoFormProps> = ({
  subscriptionTrialInfo,
  ticketIndex,
  attendeeIndex,
  checkoutValue,
}: IAttendeeInfoFormProps) => {
  const theme = useMantineTheme();

  const form = useCheckoutAttendeesFormContext();
  const { isLoggedIn, createAttendeeForGuardianMutation, eligibleAttendees, setEligibleAttendees } =
    useCheckoutDetailsContext();

  const checkoutAttendeesFormId = form.values.tickets[ticketIndex].attendees[attendeeIndex].id;
  const checkoutAttendeesFormIdPath = `tickets.${ticketIndex}.attendees.${attendeeIndex}.id`;
  const checkoutAttendeesFormPath = `tickets.${ticketIndex}.attendees.${attendeeIndex}`;

  //Attendees assigned withing the ticket
  const ticketSelectedChildIds = form.values.tickets[ticketIndex].attendees.map(
    (attendee) => attendee.id,
  );

  const getSelectedAttendeesForSpecificClass = () => {
    if (!checkoutValue) return [];
    return form.values.tickets
      .filter((ticket) => ticket.product?.checkoutValue === checkoutValue)
      .flatMap((ticket) => ticket.attendees.map((attendee) => attendee.id));
  };

  const selectedAttendees = getSelectedAttendeesForSpecificClass();

  const getSelectedChild = (id: string | null) => {
    return eligibleAttendees.find((item) => item.id === id) || null;
  };

  const [showNewAttendeeInput, setShowNewAttendeeInput] = useState(false);
  const [selectedRegisteredAttendee, setSelectedRegisteredAttendee] = useState<Child | null>(
    getSelectedChild(checkoutAttendeesFormId || null),
  );

  //to set Combobox selected option
  useEffect(() => {
    setSelectedRegisteredAttendee(
      getSelectedChild(form?.values.tickets[ticketIndex].attendees[attendeeIndex].id || null),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eligibleAttendees, form.values.tickets[ticketIndex].attendees[attendeeIndex].id]);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const options = [
    ...eligibleAttendees.map((attendee) => (
      <Combobox.Option
        value={attendee.id}
        key={`tickets.${ticketIndex}.attendees.${attendeeIndex}.${attendee.id}`}
        className={classes.item}
        disabled={
          ticketSelectedChildIds.includes(attendee.id) || selectedAttendees.includes(attendee.id)
        }
        onClick={() => trackAction(Actions.ATTENDEE_DROPDOWN_SELECT)}
      >
        <Flex align="center">
          <Text fw={600}>{attendee.fullName}</Text>
          <Text c={theme.colors.gray[6]} size="md" ml="sm">
            {`${attendee?.isAdult ? '(Adult)' : dayjs(attendee?.birthDate).format('DD / MM / YY')}`}
          </Text>
        </Flex>
      </Combobox.Option>
    )),
    <Combobox.Option
      value={'addNew'}
      key={'addNew'}
      className={classes.item}
      onClick={() => trackAction(Actions.ATTENDEE_DROPDOWN_V2)}
    >
      <Text fw={600}>Create new attendee</Text>
    </Combobox.Option>,
  ];

  const newAttendeeForm = useForm<PreCheckoutBasketAttendee>({
    initialValues: {
      id: uuidv4(),
      fullName: '',
      birthDate: '',
      isAdult: false,
      isNew: true,
    },
    validate: {
      fullName: (value) => {
        if (!value || !validateFullName(value)) {
          return 'Please enter a first and last name for this attendee.';
        }
      },
      birthDate: (value, values) => {
        if (values?.isAdult || (value && value.trim())) return null;
        return 'Must be in DD/MM/YYYY format.';
      },
    },
  });

  const handleCreateAttendee = async () => {
    if (isLoggedIn) {
      const input = {
        fullName: newAttendeeForm.values.fullName,
        ...(newAttendeeForm.values.birthDate && {
          birthDate: newAttendeeForm.values.birthDate,
        }),
        isAdult: newAttendeeForm.values.isAdult,
      };
      const response = await createAttendeeForGuardianMutation(input);
      if (response?.id) {
        form.setFieldValue(checkoutAttendeesFormIdPath, response.id);
        setShowNewAttendeeInput(false);
      }
    } else {
      setEligibleAttendees((prev) => [...prev, newAttendeeForm.values as Child]);
      form.setFieldValue(checkoutAttendeesFormPath, newAttendeeForm.values);
      setShowNewAttendeeInput(false);
    }

    trackAction(Actions.ATTENDEE_CONFIRM_V2);
  };

  const getDateValue = () => {
    const dateString = newAttendeeForm.values.birthDate;
    if (!dateString) return null;

    const date: Date = new Date(dateString);
    return date;
  };

  const trialLabel = useMemo(() => {
    if (subscriptionTrialInfo.trialType === SubscriptionTrialTypeEnum.FREE_TRIAL) {
      if (subscriptionTrialInfo.trialLength === 1) {
        return 'Not been before? Tick for a free trial session.';
      }
      return `Not been before? Tick for ${subscriptionTrialInfo.trialLength} free trial sessions.`;
    }
    if (subscriptionTrialInfo.trialType === SubscriptionTrialTypeEnum.PAID_TRIAL) {
      return `Not been before? Tick for a paid trial period.`;
    }
  }, [subscriptionTrialInfo]);

  const trialInfo = useMemo(() => {
    const trialLength = subscriptionTrialInfo.trialLength || 1;
    const baseSubsInfo = (
      <>
        <p>The subscription begins after the trial ends unless cancelled at your request.</p>

        <p>
          If continued, we'll calculate the amount you need to pay for the remaining days in the
          month. We'll charge this immediately after the trial ends.
        </p>
        <p>
          If you do not wish to continue, then you will need to contact the provider to request
          cancellation after the trial period has ended.
        </p>
      </>
    );
    if (subscriptionTrialInfo.trialType === SubscriptionTrialTypeEnum.FREE_TRIAL) {
      const intro =
        trialLength === 1
          ? 'For first-time attendees the first session will be free.'
          : `For first-time attendees the first ${trialLength} sessions will be free.`;

      return (
        <>
          <p>{intro}</p>
          {baseSubsInfo}
        </>
      );
    }
    if (subscriptionTrialInfo.trialType === SubscriptionTrialTypeEnum.PAID_TRIAL) {
      const intro =
        trialLength === 1
          ? 'For first-time attendees the first session will be a paid trial.'
          : `For first-time attendees the first ${trialLength} sessions will be a paid trial.`;

      return (
        <>
          <p>{intro}</p>
          {baseSubsInfo}
        </>
      );
    }
    return null;
  }, [subscriptionTrialInfo]);
  return (
    <>
      <Text py="xs" className={classes.subheaderText}>
        {`Attendee ${attendeeIndex + 1}`}
      </Text>

      <Combobox
        store={combobox}
        onOptionSubmit={(value: string | null) => {
          if (value === 'addNew') {
            setShowNewAttendeeInput(true);
            form.clearErrors();
          } else {
            form.setFieldValue(checkoutAttendeesFormIdPath, value);
          }
          combobox.closeDropdown();
        }}
        offset={0}
        withinPortal={false}
        middlewares={{ flip: false, shift: true, inline: false }}
      >
        <Combobox.Target>
          <InputBase
            component="button"
            type="button"
            pointer
            mb="4px"
            rightSection={<CaretDown size={16} weight="bold" color={theme.colors.blue[8]} />}
            rightSectionPointerEvents="none"
            disabled={showNewAttendeeInput}
            onClick={() => {
              combobox.toggleDropdown();
              form.clearErrors();
              trackAction(Actions.ATTENDEE_DROPDOWN);
            }}
            value={checkoutAttendeesFormId || ''}
            error={!showNewAttendeeInput && form?.errors[checkoutAttendeesFormIdPath]}
            classNames={{
              input: classNames(classes.input, {
                [classes.newAttendeeSelectFocused]: showNewAttendeeInput,
              }),
            }}
          >
            {showNewAttendeeInput ? (
              <Input.Placeholder>Create new attendee</Input.Placeholder>
            ) : checkoutAttendeesFormId && selectedRegisteredAttendee ? (
              <Flex align={'center'} gap={4}>
                <Text>{selectedRegisteredAttendee.fullName}</Text>
                <Text c={theme.colors.gray[6]}>
                  {selectedRegisteredAttendee?.isAdult
                    ? '(Adult)'
                    : dayjs(selectedRegisteredAttendee?.birthDate).format('(DD / MM / YY)')}
                </Text>
              </Flex>
            ) : (
              <Input.Placeholder>Select attendee</Input.Placeholder>
            )}
          </InputBase>
        </Combobox.Target>

        <Combobox.Dropdown className={classes.dropdown}>
          <Combobox.Options mah={220} style={{ overflowY: 'auto' }}>
            {options}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>

      {showNewAttendeeInput && (
        <>
          <PebbleTextInput
            required
            label={`Attendee name`}
            placeholder="Enter name"
            whiteBackground
            blueRequired
            data-testid={`attendee-name-input-${ticketIndex}-${attendeeIndex}`}
            value={newAttendeeForm.values.fullName || ''}
            onChange={(e) => newAttendeeForm.setFieldValue('fullName', e.target.value)}
            action={Actions.ATTENDEE_NAME_V2}
            error={newAttendeeForm?.errors.fullName}
          />

          {!newAttendeeForm.values.isAdult && (
            <DateInput
              required
              label="Date of birth"
              placeholder="DD/MM/YYYY"
              defaultLevel="decade"
              dateParser={(dateString: string) =>
                dayjs(dateString, ['DD-MM-YYYY', 'DDMMYYYY', 'DD/MM/YYYY'], true).toDate()
              }
              valueFormat="DD/MM/YYYY"
              popoverProps={{
                position: 'bottom-start',
              }}
              minDate={new Date(1900, 0, 1)}
              maxDate={new Date()}
              classNames={{
                input: classes.input,
                label: classes.inputLabel,
                required: classes.required,
                day: classes.calendarCell,
              }}
              style={{
                marginTop: theme.spacing.md,
              }}
              data-testid={`attendee-dob-input-${ticketIndex}-${attendeeIndex}`}
              error={newAttendeeForm?.errors.birthDate}
              value={getDateValue()}
              onChange={(date) => {
                if (date === null) {
                  newAttendeeForm.setFieldValue('birthDate', null);
                } else {
                  const formattedDOB = dayjs(date).format('YYYY-MM-DD');
                  newAttendeeForm.setFieldValue('birthDate', formattedDOB);
                }
              }}
              onBlur={() => trackAction(Actions.ATTENDEE_DOB_V2)}
            />
          )}

          <PebbleCheckbox
            label={'Attendee is over 18'}
            checked={newAttendeeForm.values.isAdult || false}
            onChange={(value) => {
              if (value.currentTarget.checked) {
                newAttendeeForm.setFieldValue('birthDate', null);
                trackAction(Actions.ATTENDEE_IS_ADULT_V2);
              }
              newAttendeeForm.setFieldValue('isAdult', value.currentTarget.checked);
            }}
            styles={{
              root: {
                display: 'inline-block',
              },
              label: {
                fontWeight: 'bold',
              },
            }}
          />
          <Flex justify="space-between">
            <Button
              variant="outline"
              className={classes.confirmAttendee}
              onClick={() => {
                newAttendeeForm.validate();
                if (newAttendeeForm.isValid()) {
                  handleCreateAttendee();
                }
              }}
            >
              Confirm
            </Button>
            <Button
              variant="subtle"
              onClick={() => {
                newAttendeeForm.reset();
                setShowNewAttendeeInput(false);
                trackAction(Actions.ATTENDEE_CANCEL_V2);
              }}
            >
              Cancel
            </Button>
          </Flex>
        </>
      )}
      {subscriptionTrialInfo.trialType !== SubscriptionTrialTypeEnum.NO_TRIAL && (
        <div
          className={classNames(classes.consentBoxWrapper, {
            [classes.additionalPadding]: showNewAttendeeInput,
          })}
        >
          <PebbleCheckbox
            label={trialLabel}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onClick={(e: any) => {
              if (e.target.checked) {
                trackAction(Actions.TASTER_CHECKOUT);
              }
            }}
            data-testid="subscriptionTrialSelected"
            {...form.getInputProps(`tickets.${ticketIndex}.subscriptionTrialSelected`, {
              type: 'checkbox',
            })}
          />
          <PebblePopover
            position="bottom-end"
            popoverTarget={
              <ActionIcon size="sm" aria-label="Show popover" variant="subtle">
                <Info size={24} color={theme.colors.blue[8]} />
              </ActionIcon>
            }
            width={{ mobile: '84%', desktop: 337 }}
          >
            {trialInfo}
          </PebblePopover>
        </div>
      )}
    </>
  );
};

export default AttendeeInfoForm;
